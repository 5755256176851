import { Avatar, Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core";
import { UnverifiedAgentsData } from "states/data/unverified-agents";
import { DDMMYYTime } from "utils/datetime";

const useStyles = makeStyles((theme) => ({
    inline: {
        display: 'inline',
    },
}));

type UnverifiedAgentsInfoProps = {
    agentData: UnverifiedAgentsData;
}

function UnverifiedAgentsInfoCard({ agentData }: UnverifiedAgentsInfoProps) {
    const agentInfo = agentData.agent_info;

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item>
                        <Avatar className="mt-1" alt={`${agentInfo.name} photo`} src={agentInfo.photoURL} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6" component="h2">
                            {agentInfo.name || 'No name'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {agentInfo.phone || 'No phone'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Created: <b>{DDMMYYTime(agentData.agent_info?.created_date)}</b>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            <em>
                                {agentInfo.homeAddress?.fullAddress || 'No home address'}
                            </em>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default UnverifiedAgentsInfoCard;
