import { createSlice } from "@reduxjs/toolkit";

const orders_root_layer = 1
const agents_root_layer = 0
const task_log_root_layer = 2
const unverified_agents_root_layer = 3

const order_layers = {
  all_orders: 0,
  order_route: 1,
  selected_orders: 2,
  arc_order_all: 3,
  arc_order_selected: 4
}

const agent_layers = {
  agent_route_hovered: 0,
  agent_route_selected: 1,
  all_agents: 2,
  agent_home_hovered: 3
}

const task_log_layers = {
  task_log_route: 0
}

const unverified_agents_layers = {
  unverified_agents: 0
}

const initState = [
  [
    null, // All orders - filter and search
    null, // Order routemap
    null, // When orders are selected
    null, // All orders Arc layer
    null, // Selected orders Arc layer
  ], // Order layer
  [
    null, // Agent hovered routemap
    null, // Agent selected routemap
    null, // All agents - filter, search
    null // Agent hovered home arc
  ], // Agent Layer
  [
    null // Agent routemap
  ], // Task Log Layer
  [
    null // unverified agents
  ]
];

const layerData = createSlice({
  name: "layers",
  initialState: initState,
  reducers: {
    setLayers: (state, action: { payload: any }) => {
      state = action.payload;
    },
    setLayerOrders: (
      state,
      action: {
        payload: {
          allOrdersLayer?: any;
          selectedOrdersLayer?: any;
          arcOrderAll?: any;
          arcOrderselected?: any;
        };
      }
    ) => {
      state[orders_root_layer][order_layers.all_orders] = action.payload.allOrdersLayer;
      state[orders_root_layer][order_layers.selected_orders] = action.payload.selectedOrdersLayer;
      state[orders_root_layer][order_layers.arc_order_all] = action.payload.arcOrderAll;
      state[orders_root_layer][order_layers.arc_order_selected] = action.payload.arcOrderselected;
    },
    setLayerOrderRoute: (state, action: { payload: any }) => {
      state[orders_root_layer][order_layers.order_route] = action.payload;
    },
    setLayerAgents: (state, action: { payload: any }) => {
      state[agents_root_layer][agent_layers.all_agents] = action.payload;
    },
    setLayerAgentRoute: (state, action: { payload: any }) => {
      state[agents_root_layer][agent_layers.agent_route_hovered] = action.payload;
    },
    setLayerAgentSelectedHome: (state, action: { payload: any }) => {
      state[agents_root_layer][agent_layers.agent_home_hovered] = action.payload;
    },
    setLayerAgentSelectedRoute: (state, action: { payload: any }) => {
      state[agents_root_layer][agent_layers.agent_route_selected] = action.payload;
    },
    setLayerTaskLogRoute: (state, action: { payload: any}) => {
      state[task_log_root_layer][task_log_layers.task_log_route] = action.payload
    },
    setLayerAgentUnverified: (state, action: { payload: any }) => {
      state[unverified_agents_root_layer][unverified_agents_layers.unverified_agents] = action.payload
    },
    resetLayers: (state) => initState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setLayers,
  setLayerAgents,
  setLayerOrders,
  setLayerOrderRoute,
  setLayerAgentRoute,
  setLayerAgentSelectedRoute,
  setLayerAgentSelectedHome,
  setLayerTaskLogRoute,
  setLayerAgentUnverified,
  resetLayers,
} = layerData.actions;
export const layersSlice = (state) => state.deckLayers;
export default layerData.reducer;
