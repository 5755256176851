import { List, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateLatLng } from "states/deck.gl/maps";

import UnverifiedAgentsInfo from "apps/ops-dashboard/components/unverified-agents/UnverifiedAgentsInfo";
import { UnverifiedAgentsData, setUnverifiedAgentHovered } from "states/data/unverified-agents";
// import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   KeyboardTimePicker,
//   MuiPickersUtilsProvider,
//   TimePicker
// } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  }
}));

type AllAgentsProps = {
  agents: UnverifiedAgentsData[];
};

const AllAgents = ({agents}: AllAgentsProps) => {
  const dispatch = useDispatch();

  const onMouseEnter = (unverifiedAgentDataInfo: UnverifiedAgentsData['agent_info']) => {
    return () => {
      if (!unverifiedAgentDataInfo.homeAddress) return;
      dispatch(
        updateLatLng({
          lat: unverifiedAgentDataInfo.homeAddress.coordinates.lat,
          lng: unverifiedAgentDataInfo.homeAddress.coordinates.lng,
        })
      );
      dispatch(setUnverifiedAgentHovered({ object: unverifiedAgentDataInfo, sidebarHover: true }));
    };
  };

  const classes = useStyles();

  return (
    // <Virtuoso
    //   style={{ height: "80vh" }}
    //   totalCount={agents.length}
    //   itemContent={(index) => (
    //     <div
    //       onMouseEnter={() => dispatch(onMouseEnter(agents[index]))}
    //       onMouseLeave={() => dispatch(setHoveredAgent({ object: null }))}
    //     >
    <List className={classes.root}>
      {agents.map((agent, index) => (<UnverifiedAgentsInfo
        key={index} agentData={agent} onMouseEnterFn={onMouseEnter}
      />))}
    </List>
          
    //       { agents[index].onDutyMode && <SelectAgent agentDetails={agents[index]} />}
    //       <hr />
    //     </div>
    //   )}
    // />
  );
};

export default AllAgents;
