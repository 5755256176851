import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Tab, Tabs, TextField, makeStyles } from '@material-ui/core'
import { useFetchUnverifiedAgents } from 'apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents'
import { useSnackbar } from 'notistack'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, DatePicker } from 'rsuite'
import APIReq from 'services/api'
import { rejectUnverifiedAgentsSlice, resetRejectUnverifiedAgents } from 'states/data/unverified-agents'

const styles = makeStyles({
    rejectOptions: {
        padding: "1rem",
        overflow: "wrap",
        height: 300,
        width: 500,
        "& > div": {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "start",
            gap: "2rem"
        }
    }
})

function RejectAgentVerification() {

    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = React.useState<"dnd" | "delete">("dnd")
    const [deleteAgentConfirmation, setDeleteAgentConfirmation] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const [dndUpto, setDndUpto] = React.useState<undefined | Date>(undefined)
    const [loadingState, setLoadingState] = React.useState<"Settings agents DnD" | "Deleting agents" | null>(null)
    const snackbar = useSnackbar()
    const selectedUnverifiedAgentsToReject = useSelector(rejectUnverifiedAgentsSlice)
    const classes = styles();

    const {
        refetch: refetchUnverifiedAgents,
    } = useFetchUnverifiedAgents()

    const resetState = () => {
        setDeleteAgentConfirmation(false)
        setReason("")
        setDndUpto(undefined)
    }

    const onSelectOption = (option) => {
        if (option == selectedOption) return
        resetState()
        setSelectedOption(option)
    }

    const onCancel = () => {
        resetState()
        dispatch(resetRejectUnverifiedAgents())
    }

    const onReject = () => {
        if (selectedOption === "dnd") {
            let duration: undefined | number = undefined
            if (dndUpto) {
                duration = dndUpto.getTime() - new Date().getTime()
            }
            selectedUnverifiedAgentsToReject.forEach(agent => {
                setLoadingState("Settings agents DnD")
                APIReq.AgentApis.setUnverifiedAgentDnD(
                    agent.agentID,
                    true,
                    duration,
                    reason
                ).then(() => {
                    snackbar.enqueueSnackbar("Agent DnD set successfully", { variant: "success" })
                }).catch(() => {
                    snackbar.enqueueSnackbar("Failed to set DnD", { variant: "error" })
                }).finally(() => {
                    setLoadingState(null)
                    onCancel()
                })
            })
        }
        if (selectedOption === "delete" && deleteAgentConfirmation){
            selectedUnverifiedAgentsToReject.forEach(agent => {
                setLoadingState("Deleting agents")
                APIReq.AgentApis.deleteUnverifiedAgent(agent.agentID)
                .then(() => {
                    snackbar.enqueueSnackbar("Agent deleted successfully", { variant: "success" })
                }).catch(() => {
                    snackbar.enqueueSnackbar("Failed to delete agent", { variant: "error" })
                }).finally(() => {
                    refetchUnverifiedAgents()
                    setLoadingState(null)
                    onCancel()
                })
            })
        }
        resetState()
        dispatch(resetRejectUnverifiedAgents)
    }

    const enableRejectButton = useMemo(() => {
        if (selectedOption === "dnd") {
            return true
        }
        if (selectedOption === "delete") {
            return deleteAgentConfirmation
        }
    }, [selectedOption, deleteAgentConfirmation])


return (
    <Dialog open={selectedUnverifiedAgentsToReject.length}>
        <DialogTitle>Reject Agent Verification</DialogTitle>
        {loadingState ? (<div>
            {loadingState} {" ..."}
        </div>)
        :(<><DialogContent>
            <div className='mv-2'>
                Agent{selectedUnverifiedAgentsToReject.length ? "s" : ""}:{" "}
                {selectedUnverifiedAgentsToReject.map((agent) => (
                    <Chip label={agent.name} />
                ))}
            </div>
            <div>
                <Tabs
                    value={selectedOption}
                    onChange={(e, value) => onSelectOption(value)}
                >
                    <Tab value="dnd" label="Do Not Disturb" />
                    <Tab value="delete" label="Delete" />
                </Tabs>
            </div>
            <div className={classes.rejectOptions}>
                {selectedOption === "dnd" && (
                    <div>
                        <TextField
                            label="Enter the reason here"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                        <DatePicker
                            size="lg"
                            placeholder="Pick date"
                            label="Set DnD upto"
                            value={dndUpto}
                            onChange={(value) => setDndUpto(value)}
                            ranges={[
                                {
                                    label: 'Tomorrow',
                                    value: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                                },
                                {
                                    label: 'Next 7 days',
                                    value: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
                                },
                                {
                                    label: 'Next 30 days',
                                    value: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
                                }
                            ]}
                            allowedRange={[new Date(), new Date(new Date().getTime() + 45 * 24 * 60 * 60 * 1000)]}
                        />
                    </div>
                )}
                {selectedOption === "delete" && (
                    <div>
                        <p>
                            This will delete the agent from our system. It cannot be undone.
                            Are you sure you want to delete this agent?
                        </p>
                        <FormControlLabel
                            className="mt-2"
                            control={
                                <Checkbox
                                    checked={deleteAgentConfirmation}
                                    onChange={(value, checked) => setDeleteAgentConfirmation(checked)}
                                    name="DeleteAgentConfirmation"
                                />
                            }
                            label="Yes, I want to delete this agent."
                        />
                    </div>
                )}
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={onReject} disabled={!enableRejectButton}>
                {selectedOption === "dnd" && "Postpone Verification"}
                {selectedOption === "delete" && "Delete Agent"}
            </Button>
            <Button onClick={onCancel}>
                <span className="text-danger">Cancel</span>
            </Button>
        </DialogActions></>)}
    </Dialog>
);
}

export default RejectAgentVerification