import { makeStyles } from "@material-ui/core";
import AgentInfoCard from "apps/ops-dashboard/components/agents/AgentInfoCard";
import OrdersCard from "apps/ops-dashboard/components/orders/OrdersCard";
import UnverifiedAgentsInfoCard from "apps/ops-dashboard/components/unverified-agents/UnverifiedAgentInfoCard";
import { useSelector } from "react-redux";
import { hoveredUnverifiedAgentSlice } from "states/data/unverified-agents";

const styles = makeStyles({
  infoWindow: {
    position: "fixed",
    bottom: 48,
    left: 16,
    width: 360,
    background: 'white'
  },
});

function Info({ children }) {
  const classes = styles();
  return <div className={classes.infoWindow}>{children}</div>;
}

function InfoWindow(params) {

  const orderHoverInfo = useSelector(
    (state: any) => state.ordersData.hoveredInfo
  );
  const agentHoverInfo = useSelector(
    (state: any) => state.agentsData.hoveredAgent
  );

  const hoveredUnverifiedAgent = useSelector(hoveredUnverifiedAgentSlice);

  if (orderHoverInfo?.object) {
    return (
      <Info>
        <OrdersCard orderDetails={orderHoverInfo.object} noPadding />
      </Info>
    );
  }

  if (agentHoverInfo?.object && !agentHoverInfo?.sidebarHover) {
    return (
      <Info>
        <AgentInfoCard agentData={agentHoverInfo.object} />
      </Info>
    );
  }

  if (hoveredUnverifiedAgent?.object && !hoveredUnverifiedAgent?.sidebarHover) {
    return (
      <Info>
        <UnverifiedAgentsInfoCard agentData={hoveredUnverifiedAgent.object} />
      </Info>
    );
  }

  return <></>;
}

export default InfoWindow;
