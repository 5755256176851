import { AGENT_GENERAL_FILTER_QUERY_PARAM, UNVERIFIED_AGENT_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { FilterData, FilterInterface, FilterOptionWithFilterType } from "types/filter-types";
import { getQueryParam, updateQueryParam } from "utils/history";
import { getBusinessFilters, orderFilters } from "../orders/filters";
import { agentsFilters } from "../agents/filters";

/**
 *
 * @param processedFilter Current set of filter options
 * @param query Search filters using this query
 * @returns new set of filter options
 */
export const performFilterSearch = (
    processedFilter: FilterOptionWithFilterType[],
    query: string
  ) => {
    const lCQuery = query.toLocaleLowerCase();
  
    if (lCQuery === "") {
      return processedFilter;
    }
  
    const newFilter = processedFilter.map((filterOption) => {
      const f = { ...filterOption };
      f.items = f.items.filter((x) => {
        return x.name.toLocaleLowerCase().indexOf(lCQuery) > -1;
      });
      return f;
    });
  
    return newFilter;
  }
  
/**
 * 
 * @param key Key of the filter option to be removed
 * @returns stringified version of the new filter options
 * 
 * @description Removes the filter option with the given key from the filter options
 */
export const clearOrderFilterQuery = (key): string => {
    const orderFilters: FilterInterface[] = JSON.parse(
      getQueryParam(ORDER_FILTER_QUERY_PARAM) || "[]"
    ).filter(x => x.key !== key)

    return JSON.stringify([...orderFilters]);
  }

/**
 * 
 * @param key Key of the filter option to be removed
 * @returns stringified version of the new filter options
 * 
 * @description Removes the filter option with the given key from the filter options
 */
export const clearAgentFilterQuery = (key): string => {
  
  const agentFilters: FilterInterface[] = JSON.parse(
      getQueryParam(AGENT_GENERAL_FILTER_QUERY_PARAM) || "[]"
  ).filter((f) => f.key !== key);

  return JSON.stringify([...agentFilters]);
}


export const filterOrder = [
  { key: "vehicleCategory", filterOn: 'agent'},
  { key: "requestedAtTimestamp", filterOn: 'order'},
  { key: "slaDrop", filterOn: 'order'},
  { key: "orderStatus", filterOn: 'order'},
  { key: "pack.type", filterOn: 'order'},
  { key: "ship.type", filterOn: 'order'},
  // { key: "WhOrderStatus", filterOn: 'order'},
  { key: "agentState", filterOn: 'agent' },
  { key: "agent_onboard_stage", filterOn: 'unverified_agents' },
  { key: "limit", filterOn: 'unverified_agents' },
  { key: "sort", filterOn: 'unverified_agents' },
  { key: "time_filter", filterOn: 'unverified_agents' },
  { key: "business_code", filterOn: 'order'}
]

export const prepareFilter = (businesses_details?, unverifiedAgentFilters?): FilterOptionWithFilterType[] => {
    const updatedOrderFilters = orderFilters;
  updatedOrderFilters.business_code.items = getBusinessFilters(businesses_details);
    // const updatedOrderFiltersList = Object.keys(updatedOrderFilters).map((key) => {
    //   return { ...updatedOrderFilters[key], ...{key: key, filterOn: 'order'} } as FilterOptionWithFilterType;
    // });
    // const updatedAgentFilters = agentsFilters;
    // const updatedAgentFiltersList = Object.keys(updatedAgentFilters).map((key) => {
    //   return { ...updatedAgentFilters[key], ...{key: key, filterOn: 'agent'} } as FilterOptionWithFilterType;
    // });

    // return [...updatedOrderFiltersList, ...updatedAgentFiltersList];
  let orderedFilters: FilterOptionWithFilterType[] = filterOrder.filter((filter) => {
    if (filter.filterOn === 'unverified_agents' && !unverifiedAgentFilters) {
      return false;
    }
    return true;
    }).map((filter) => {
      // const filterObject = filter.filterOn === 'order' ? updatedOrderFilters[filter.key] : agentsFilters[filter.key];
      let filterObject;
      if (filter.filterOn === 'order') {
        filterObject = updatedOrderFilters[filter.key];
      }
      else if (filter.filterOn === 'agent') {
        filterObject = agentsFilters[filter.key];
      }
      else if (filter.filterOn === 'unverified_agents' && unverifiedAgentFilters[filter.key]) {
        filterObject = unverifiedAgentFilters[filter.key];
      }
      else { 
        filterObject = null;
      }
      return { ...filterObject, ...filter } as FilterOptionWithFilterType;
    });
    return orderedFilters;
  }


/**
 * Thunk - Filters can be applied in the form of ['key', 'value'] pair on orders data, this function
 * takes care of setting the state of filter and performing the filter if updateOrdersState
 * is true
 * @param key filter on key dotnotated
 * @param value value to filter on
 * @param properties Extra set of properties to apply to the filter
 * @param updateOrdersState should the order list state be updated
 * @returns null
 */
export const generateFilterQuery = (key, value, filterOn: FilterOptionWithFilterType['filterOn'], filterData: FilterData): string => {
  // generates filter query for the given key and value
  let filterQueryParam;
  if (filterOn === 'order') {
    filterQueryParam = ORDER_FILTER_QUERY_PARAM;
  }
  if (filterOn === 'agent') {
    filterQueryParam = AGENT_GENERAL_FILTER_QUERY_PARAM;
  }
  if (filterOn === 'unverified_agents') {
    filterQueryParam = UNVERIFIED_AGENT_FILTER_QUERY_PARAM;
  }

  const filters: FilterInterface[] = JSON.parse(
    getQueryParam(filterQueryParam) || "[]"
  );
  // Find if the filter key exists
  const i = filters.findIndex((x) => x.key === key);

  // When no object with the given key exist
  if (i === -1) {
    filters.push({
      key: key,
      values: [value],
    });
  } else {
    // When object with key exists
    // Create a deep copy 
    const filterItem: FilterInterface = filters[i];

    // Only one option in radio can be selected, set to empty array if value is null
    if (filterData[key].elementType === "radio") {
      filterItem.values = value ? [value] : []
    } else {
      // If the value exists in the values key, remove it or else append it
      const filterValueIndex = filterItem.values.indexOf(value);
      if (filterValueIndex > -1) {
        filterItem.values.splice(filterValueIndex, 1);
      } else {
        filterItem.values.push(value);
      }
    }

    // Filters should not have an object with empty values
    if (!filterItem.values.length) {
      filters.splice(i, 1);
    } else {
      filters[i] = filterItem;
    }
  }
  return JSON.stringify(filters);
};
  

/**
 * Sets default filters for orders, agents and unverified agents if no filters are set
 * @param filterData
 * @param queryParamKey
 * @param history
 * @returns null
 * 
 */
export const setDefaultFilters = (filterData: FilterData, filterOn: FilterOptionWithFilterType['filterOn'],  queryParamKey: string, history) => {
  const currrentFilterQueries = JSON.parse(getQueryParam(queryParamKey) || "[]");
  if (currrentFilterQueries.length > 0) {
    return
  }
  const defaultFilters = Object.keys(filterData)
    .filter((key) => filterData[key].default !== undefined)
    .map((key) => {
    return {
      key: key,
      value: filterData[key].default,
    };
  });
  defaultFilters.forEach((filter) => {
    history.replace(
      updateQueryParam(queryParamKey, generateFilterQuery(filter.key, filter.value, filterOn, filterData))
    );
  })
}
