import APIReq from 'services/api'
import { checkAdminAuth } from '.'

const login = (email: string, password: string) => (dispatch) => {
  APIReq.auth.loginAdmin(email, password)
    .then((res) => {
      dispatch(checkAdminAuth())
    })
    .catch(() => null)
}

export default login;