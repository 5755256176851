import {
  Collapse,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { updateOrdersWithFilter } from "apps/ops-dashboard/services/orders/filters";
import { businessesDetailSlice } from "states/data/orders";

import { useEffect, useMemo, useState } from "react";
import { getQueryParamJSONArray, useQueryParams } from "utils/history";
import FilterItem from "./FilterItem";
import { useDispatch, useSelector } from "react-redux";
import { allowInvertFilters, allowMultiSelectOptions } from "global-constants/orders/filters";
import { INVERT_FILTER_SELECTION, ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { FilterInterface } from "types/filter-types";
import { performFilterSearch, prepareFilter } from "apps/ops-dashboard/services/filters";
import { AGENT_GENERAL_FILTER_QUERY_PARAM, UNVERIFIED_AGENT_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { updateAgentsWithFilter } from "apps/ops-dashboard/services/agents/filters";
import { useFetchUnverifiedAgentFilters } from "apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents";
import { authDataSlice } from "states/auth";


const styles = makeStyles({
  filterContainer: {
    marginTop: 4,
    maxHeight: window.innerHeight - 126,
    overflow: 'auto',
    paddingTop: 8,
    borderRadius: '5px',
    fontSize: ".8rem"
  },
});


const FilterSearch = () => {
  const classes = styles()  
  const dispatch = useDispatch();

  const query = useQueryParams();
  const businessesDetail = useSelector(businessesDetailSlice)
  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchUnverifiedAgentFilters()
  const preparedFilter = useMemo(() => {
    if (!error && !isLoading && !isFetching && data) {
      return prepareFilter(businessesDetail, data)
    }
    return prepareFilter(businessesDetail)
  }, [businessesDetail, data, error, isLoading, isFetching]);
  const [processedFilter, setProcessedFilter] = useState(preparedFilter);
  const [prevInvertFilterString, setPrevInvertFilterString] = useState("[]");

  const [filterQuery, setFilterQuery] = useState("");
  const [orderFilterList, setOrderFilterList] = useState<FilterInterface[]>([])
  const [agentFilterList, setAgentFilterList] = useState<FilterInterface[]>([])
  const [unverifiedAgentFilterList, setUnverifiedAgentFilterList] = useState<FilterInterface[]>([])
  const authData = useSelector(authDataSlice)
  
  // Avoid setting the filters when other queryparam changes occur in url
  useEffect(() => {
    const searchParam = query.get("filterQuery");
    if (searchParam !== filterQuery) {
      setFilterQuery(searchParam || "");
    }
    if (JSON.stringify(orderFilterList) !== (query.get(ORDER_FILTER_QUERY_PARAM) || "[]")) {
      setOrderFilterList(getQueryParamJSONArray(ORDER_FILTER_QUERY_PARAM))
    }
    if (JSON.stringify(agentFilterList) !== (query.get(AGENT_GENERAL_FILTER_QUERY_PARAM) || "[]")) {
      setAgentFilterList(getQueryParamJSONArray(AGENT_GENERAL_FILTER_QUERY_PARAM))
    }
    if (JSON.stringify(unverifiedAgentFilterList) !== (query.get(UNVERIFIED_AGENT_FILTER_QUERY_PARAM) || "[]")) {
      setUnverifiedAgentFilterList(getQueryParamJSONArray(UNVERIFIED_AGENT_FILTER_QUERY_PARAM))
    }
  }, [filterQuery, query]);

  useEffect(() => {
    if (authData?.isMultiTenantSystem) return
    setProcessedFilter(performFilterSearch(preparedFilter, filterQuery));
  }, [filterQuery, businessesDetail]);

  useEffect(() => {
    const invertFilterString = query.get(INVERT_FILTER_SELECTION) || "[]";
    if(invertFilterString === prevInvertFilterString) return
    setPrevInvertFilterString(invertFilterString)
    dispatch(updateOrdersWithFilter());
    dispatch(updateAgentsWithFilter());
  }, [query])



  return (
    <Collapse in={!!query.get("filterQuery") || !!query.get('sbOpen')} timeout={200}>
      <Paper className={classes.filterContainer} square elevation={0}>
        {processedFilter.map((filterOption, i) => (
          <FilterItem key={i} filterOption={filterOption} orderFilterQuery={orderFilterList}
            agentFilterQuery={agentFilterList} unverifiedAgentsFilterQuery={unverifiedAgentFilterList}
           multiSelectOptions={allowMultiSelectOptions.includes(filterOption.key)}
           allowFilterInversion={allowInvertFilters.includes(filterOption.key)}/>
        ))}
      </Paper>
    </Collapse>
  );
};

export default FilterSearch;
