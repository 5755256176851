import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useQueryParams } from "utils/history";
import Header from "../header";
import AllAgents from "./AllAgents";
import { useFetchUnverifiedAgents } from "apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents";

const styles = makeStyles({
  sideBar: {
    width: 360,
  },
});

function UnverifiedAgentsSideBar() {
  const query = useQueryParams();
  const classes = styles();
  const dispatch = useDispatch()
  const {
    data: unverifiedAgentsData,
    error: unverifiedAgentsError,
    isLoading: unverifiedAgentsLoading,
    isFetching: unverifiedAgentsFetching,
    refetch: unverifiedAgentsRefetch,
  } = useFetchUnverifiedAgents()

  if (unverifiedAgentsError || unverifiedAgentsLoading || unverifiedAgentsFetching) {
    return <></>
  }

  return (
    <div className={classes.sideBar}>
      <Header title="Unverified agents" />
      <div className="mt-2"></div>
      {/* <AgentSearch /> */}
      <AllAgents agents={unverifiedAgentsData} />
    </div>
  );
}

export default UnverifiedAgentsSideBar;
