import APIReq from 'services/api'
import { isAdminSideEffect } from 'services/side-effects'
import { initAuth, unsetAuth } from 'states/auth'



const adminAuth = () => (dispatch) => {
  APIReq.auth.isAdmin()
    .then((response) => {
      dispatch(isAdminSideEffect(response.data))
    })
    .catch((e) => {
      dispatch(unsetAuth())
    })
    .finally(() => dispatch(initAuth()))
}

export default adminAuth;