import { Avatar, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip, Typography, makeStyles, Button } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { UnverifiedAgentsData, setRejectUnverifiedAgents, setUnverifiedAgentHovered } from "states/data/unverified-agents";
import { DDMMYYTime } from "utils/datetime";
import EditLocationIcon from '@material-ui/icons/EditLocation';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

const useStyles = makeStyles((theme) => ({
    inline: {
        display: 'inline',
    },
    actionButton: {
        margin: 0,
        padding: theme.spacing(0.5),
        fontSize: 12,
    }
}));


type UnverifiedAgentsInfoProps = {
    agentData: UnverifiedAgentsData;
    onMouseEnterFn: (agentData: UnverifiedAgentsData['agent_info']) => void;
}

function UnverifiedAgentsInfo({ agentData, onMouseEnterFn }: UnverifiedAgentsInfoProps) {
    const agentInfo = agentData.agent_info;

    const classes = useStyles();
    const dispatch = useDispatch();

    const [locationCopied, setLocationCopied] = React.useState(false);
    const [updatingLocation, setUpdatingLocation] = React.useState(false);

    const onLocationCopy = () => {
        navigator.clipboard.writeText(`https://www.google.co.in/maps/place/${agentInfo?.homeAddress.coordinates.lat},${agentInfo?.homeAddress.coordinates.lng}`);
        setLocationCopied(true);
        setTimeout(() => {
            setLocationCopied(false);
        }, 2000);
    }

    const onReject = () => {
        dispatch(setRejectUnverifiedAgents([{ agentID: agentData.agentID, name: (agentData.agent_info.name || agentData.agent_info.phone) }]))
    }

    return (
        <>
            <ListItem alignItems="flex-start" className="h-18 of-hidden"
                onMouseEnter={() => dispatch(onMouseEnterFn(agentData.agent_info))}
                onMouseLeave={() => dispatch(setUnverifiedAgentHovered({ object: null }))}
            >
                <ListItemAvatar>
                    <Avatar alt={`${agentInfo.name} photo`} src={agentInfo.photoURL} />
                </ListItemAvatar>
                <ListItemText
                    primary={agentInfo.name || 'No name'}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {agentInfo.phone || 'No phone'}
                            </Typography> <br />
                            <b><small> Created {DDMMYYTime(agentData.agent_info?.created_date)}</small></b>
                            <br />
                            <em>{!agentData.agent_info?.homeAddress ? (
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                    color="error"
                            >
                                No home address
                            </Typography>
                            ) : (<small> {agentData.agent_info?.homeAddress?.fullAddress} </small>)}</em>
                            {locationCopied && <span className="font-xs text-success"><em>Location Copied!!</em></span>}
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction>
                    {agentData.agent_info?.homeAddress ? <Tooltip title="Copy Location" aria-label="copy google maps link">
                        <PersonPinCircleIcon
                                color={locationCopied?"disabled":"primary"}
                                style={{ cursor: (locationCopied?"wait":"pointer"), fontSize: "24px" }}
                                onClick={onLocationCopy}
                            />
                    </Tooltip> : <Tooltip title="Update Location" aria-label="edit agent location link">
                            <EditLocationIcon color="disabled"
                                style={{ cursor: "not-allowed", fontSize: "24px" }}
                                onClick={() => setUpdatingLocation(true)}
                            />
                        </Tooltip>}
                </ListItemSecondaryAction>
            </ListItem>
            <div className="d-flex justify-right mv-2">
                <Button disabled color="secondary" size="small">Assign</Button>
                <Button color="primary" size="small" onClick={onReject}><span className="text-danger">Reject</span></Button>
            </div>
            <Divider variant="inset" component="li" />
        </>
    );
}

export default UnverifiedAgentsInfo;
