import {
  makeStyles,
  Paper,
  IconButton,
  InputBase,
  Divider,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";

import { theme } from "styles/theme";
import { useHistory } from "react-router";

import {
  toggleQueryParam,
  unsetQueryParam,
  updateQueryParam,
  useQueryParams,
} from "utils/history";
import { useEffect, useRef } from "react";
import { FILTER_BAR_OPEN_QUERY_PARAM } from ".";

const styles = makeStyles({
  filterContainer: {
    position: "absolute",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "280px",
    background: "white",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 320,
    paddingLeft: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  lightText: {
    color: "#aaaaaa",
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

const SearchBar = () => {
  const classes = styles();
  const history = useHistory();
  const query = useQueryParams();

  const inputRef = useRef<HTMLDivElement>();

  const onKeyPressF = (k: KeyboardEvent) => {
    
    if (k.key === "f" || k.key === "F") { 
      // TODO - FIX - Ignore certain elements like deckGL, since they are always focused
      if (document.querySelectorAll('*:focus').length === 0) {
        inputRef.current?.focus();
      } 
    }
    if (k.key === "Escape") {
      inputRef.current?.blur();
      history.push(unsetQueryParam("filterQuery"));
    }
  }

  useEffect(() => {
    document.body.addEventListener("keyup", onKeyPressF)
    return () => {
      document.body.removeEventListener("keyup", onKeyPressF)
    }
  }, [])

  const actionIcon = () => {
    if (query.get("filterQuery")) {
      return <CloseIcon />;
    } else {
      return query.get(FILTER_BAR_OPEN_QUERY_PARAM) ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    }
  };

  const onActionClick = () => {
    if (query.get("filterQuery")) {
      return history.push(toggleQueryParam("filterQuery"));
    }
    history.push(toggleQueryParam(FILTER_BAR_OPEN_QUERY_PARAM));
  };

  const onInput = (e) => {
    const query = e.target.value;
    if (!query || query === "") {
      history.push(unsetQueryParam("filterQuery"));
    } else {
      history.push(updateQueryParam("filterQuery", query));
    }
  };

  const onKeyDown = (e) => {
    switch(e.key) {
      case "ArrowUp":
        e.preventDefault()
        break
      case "ArrowDown":
        e.preventDefault()
        break
      case "Enter":
        e.preventDefault()
        break
    }
    
  }

  return (
    <Paper className={classes.root}>
      <SearchIcon className={classes.lightText} />
      <InputBase
        className={classes.input}
        placeholder="Search filters"
        inputProps={{ "aria-label": "search google maps" }}
        onChange={onInput}
        value={query.get("filterQuery") || ""}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={onActionClick}
      >
        {actionIcon()}
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
