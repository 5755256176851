import { IconLayer } from 'deck.gl';
import unverifiedBaseIcon from "assets/new_icons/unverified-agents/unverified_base.svg";
import { UnverifiedAgentsData } from 'states/data/unverified-agents';

export const unverifiedAgentsIconLayer = (unverifiedAgentsData: UnverifiedAgentsData[], onHoverAgent?) => {
  return (new IconLayer({
    id: 'unverified-agents-icon-layer',
    data: unverifiedAgentsData,
    sizeScale: 40,
    sizeUnits: "meters",
    sizeMinPixels: 35,
    sizeMaxPixels: 56,
    pickable: true,
    visible: true,
    getIcon: (d: any) => ({
        url: unverifiedBaseIcon,
        width: 128,
        height: 128,
        anchorY: 64,
    }),
    getPosition: (d: any) => [d.agent_info.homeAddress.coordinates.lng, d.agent_info.homeAddress.coordinates.lat],
    getSize: 5,
    getColor: [255, 140, 0],
    getTooltip: (d) => d.agent_info.name,
    onHover: onHoverAgent,
  }))
};
