import {
  makeStyles,
} from "@material-ui/core";

import { theme } from "styles/theme";
import SearchBar from "./SearchBar";
import FilterSearch from "./FilterSearch";
import { orderFilters, updateOrdersWithFilter } from "apps/ops-dashboard/services/orders/filters";
import { useEffect, useLayoutEffect, useRef } from "react";
import { getQueryParam, getQueryParamJSONArray, useQueryParams } from "utils/history";
import { useDispatch } from "react-redux";
import FilterChips from "./FilterChips";
import { FilterInterface } from "types/filter-types";
import { ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { AGENT_GENERAL_FILTER_QUERY_PARAM, UNVERIFIED_AGENT_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { agentsFilters, updateAgentsWithFilter } from "apps/ops-dashboard/services/agents/filters";
import { setDefaultFilters } from "apps/ops-dashboard/services/filters";
import { useHistory } from "react-router-dom";
import { useFetchUnverifiedAgentFilters } from "apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents";

const styles = makeStyles({
  filterContainer: {
    position: "absolute",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "320px",
  },
})

export const FILTER_BAR_OPEN_QUERY_PARAM = 'sbOpen'

const FilterBar = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const query = useQueryParams()
  const orderFilterQuery = useRef<FilterInterface[]>([])
  const agentFilterQuery = useRef<FilterInterface[]>([])
  const history = useHistory()

  useLayoutEffect(() => {
    setDefaultFilters(orderFilters, 'order', ORDER_FILTER_QUERY_PARAM, history)
    setDefaultFilters(agentsFilters, 'agent', AGENT_GENERAL_FILTER_QUERY_PARAM, history)
  }, [])

  const { data: unverifiedAgentFiltersData,
    error: unverifiedAgentsFiltersError } = useFetchUnverifiedAgentFilters();

  useEffect(() => {
    if (unverifiedAgentFiltersData && !unverifiedAgentsFiltersError) {
          setDefaultFilters(unverifiedAgentFiltersData, 'unverified_agents', UNVERIFIED_AGENT_FILTER_QUERY_PARAM, history)
        }
      }
    , [unverifiedAgentFiltersData])

  useEffect(() => {
    if (!(getQueryParam(ORDER_FILTER_QUERY_PARAM) === JSON.stringify(orderFilterQuery.current))) {
      orderFilterQuery.current = getQueryParamJSONArray(ORDER_FILTER_QUERY_PARAM)
      dispatch(updateOrdersWithFilter())
    }
    if (!(getQueryParam(AGENT_GENERAL_FILTER_QUERY_PARAM) === JSON.stringify(agentFilterQuery.current))) {
      agentFilterQuery.current = getQueryParamJSONArray(AGENT_GENERAL_FILTER_QUERY_PARAM)
      dispatch(updateAgentsWithFilter())
    }
    if (!(getQueryParam(UNVERIFIED_AGENT_FILTER_QUERY_PARAM) === JSON.stringify(agentFilterQuery.current))) {
      agentFilterQuery.current = getQueryParamJSONArray(UNVERIFIED_AGENT_FILTER_QUERY_PARAM)
      dispatch(updateAgentsWithFilter())
    }
  }, [query]);

  return (
    <div className={classes.filterContainer}>
      <SearchBar />
      <FilterSearch />
      <FilterChips />
    </div>
  )
};

export default FilterBar;
