import { useFetchUnverifiedAgents } from 'apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setLayerAgentUnverified } from 'states/deck.gl/layers'
import { unverifiedAgentsIconLayer } from './unverified-agents-icon-layer'
import { setUnverifiedAgentHovered } from 'states/data/unverified-agents'

function UnverifiedAgentLayers() {

    const dispatch = useDispatch()
    const {
        data: unverifiedAgentsData,
        error: unverifiedAgentsError,
        isLoading: unverifiedAgentsLoading,
        isFetching: unverifiedAgentsFetching,
        refetch: unverifiedAgentsRefetch,
    } = useFetchUnverifiedAgents()

    const validUnverifiedAgents = useMemo(() => 
        unverifiedAgentsData?unverifiedAgentsData.filter(agent => agent.agent_info.homeAddress):[]
        , [unverifiedAgentsData])
    
    const onHoverAgent = (hoverInfo) => {
        dispatch(setUnverifiedAgentHovered(hoverInfo));
    };

    useEffect(() => {
        if (!unverifiedAgentsError && !unverifiedAgentsLoading && !unverifiedAgentsFetching && unverifiedAgentsData.length) {
            dispatch(setLayerAgentUnverified(unverifiedAgentsIconLayer(validUnverifiedAgents, onHoverAgent)))
        }
    }, [unverifiedAgentsData])


  return (
    <></>
  )
}

export default UnverifiedAgentLayers