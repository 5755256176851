import rush from "assets/orders/rush.svg";
import now from "assets/orders/now.svg";
import xpress from "assets/orders/xpress.svg";
import standard from "assets/orders/standard.svg";
import sameday from "assets/orders/sameday.svg";
import sameday_enterprise from "assets/orders/sameday_enterprise.svg";
import nextday_enterprise from "assets/orders/nextday_enterprise.svg";
import rush_enterprise from "assets/orders/rush_enterprise.svg";
import now_enterprise from "assets/orders/now_enterprise.svg";
import xpress_enterprise from "assets/orders/xpress_enterprise.svg";
import standard_enterprise from "assets/orders/standard_enterprise.svg";

const shipTypeIconMap = {
  RUSH: rush,
  XPRESS: xpress,
  SAMEDAY:sameday,  
  STANDARD: standard, 
  SAMEDAY_ENTERPRISE: sameday_enterprise, 
  NEXTDAY_ENTERPRISE: nextday_enterprise,
  RUSH_ENTERPRISE: rush_enterprise,
  XPRESS_ENTERPRISE: xpress_enterprise,
  STANDARD_ENTERPRISE: standard_enterprise,
  NOW: now,
  NOW_ENTERPRISE: now_enterprise,
};

export const shipTypeIcons = (shipType: string) => {

  return ({
    url: shipTypeIconMap[shipType],
    width: 128,
    height: 116,
    anchorY: 64,
    mask: false,
  })}