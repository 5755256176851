import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import deckLayers from './deck.gl/layers'
import nucleusDeckLayers from './nucleus/deck.layers'
import mapViewPort from './deck.gl/maps'
import websocketConnection from './websockets/connection'
import agentsData from './data/agents'
import ordersData from './data/orders'
import configsData from './data/configs'
import taskLogData from './data/task-logs'
import keyPressStates from './keypress'
import authData from './auth'
import unverifiedAgentsData from './data/unverified-agents'



export default configureStore({
  reducer: {
    deckLayers,
    nucleusDeckLayers,
    mapViewPort,
    websocketConnection,
    agentsData,
    ordersData,
    keyPressStates,
    authData,
    configsData,
    taskLogData,
    unverifiedAgentsData
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})