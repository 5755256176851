import { createSlice } from '@reduxjs/toolkit'

const initState = {
  isAuthenticated: false,
  init: false,
  adminEmail: '',
  canViewUnverifiedAgents: false,
  isMultiTenantSystem	: false,
}

const authData = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuthenticated = true
      state.adminEmail = action.payload.email
      state.canViewUnverifiedAgents = action.payload.canViewUnverifiedAgents
      state.isMultiTenantSystem	 = action.payload.isMultiTenantSystem	
    },
    unsetAuth: (state) => {
      state.isAuthenticated = false
    },
    initAuth: (state) => {
      state.init = true
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAuth: setAuthenticated, initAuth, unsetAuth } = authData.actions

export const authDataSlice = (state: {authData: typeof initState}) => state.authData

export default authData.reducer