import { useFetchUnverifiedAgents } from "apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents";
import { useEffect } from "react";

const LoadUnverifiedAgents = () => {

    const {
        data: unverifiedAgentsData,
        error: unverifiedAgentsError,
        isLoading: unverifiedAgentsLoading,
        isFetching: unverifiedAgentsFetching,
        refetch: unverifiedAgentsRefetch,
    } = useFetchUnverifiedAgents()

    useEffect(() => {

    }, [unverifiedAgentsData])

    return <></>
}

export default LoadUnverifiedAgents;
