import { useSnackbar } from "notistack";
import APIReq from "services/api";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setFailOrderTask, setSchedulingOrder } from "states/data/orders";
import React, { useCallback } from "react";
import { Checkbox, Dropdown } from "rsuite";

const OrderActions = ({ orderDetails }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const orderStatus = orderDetails.orderStatus;
  const orderID = orderDetails.orderID;
  const dispatch = useDispatch()

  const CancelButton = () => {
    const cancelOrder = () => {
      if (!window.confirm(`Are you sure you want to cancel order ${orderID}`)) {
        return;
      }
      APIReq.orders
        .cancelOrder(orderID)
        .then((res) => {})
        .catch((err) =>
          enqueueSnackbar(`Error cancelling order ${orderID}`, {
            variant: "error",
          })
        );
    };
    return (
      <Button onClick={cancelOrder} style={{ color: "red" }}>
        Cancel
      </Button>
    );
  };

  const RescheduleButton = () => {
    const dispatch = useDispatch()
    return <Button onClick={() => dispatch(setSchedulingOrder(orderID))}>Reschedule</Button>
  };

  const ReturnButton = (props: {returned: boolean}) => {
    
    const [open, setOpen] = React.useState(false);
    const [returnNow, setReturnNow] = React.useState(false);
    const [reason, setReason] = React.useState("");

    const returnOrder = (returnNow: boolean) => {
      setReturnNow(returnNow);
      setOpen(true);
    };

    const handleReturn = () => {
      if (!window.confirm(`Are you sure you want to return order ${orderID}? This action cannot be undone.`)) {
      return;
      }
      APIReq.orders
      .returnOrder(orderID, returnNow, reason)
      .then((res) => {
        enqueueSnackbar(`Order ${orderID} set to returned`, {
        variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        enqueueSnackbar(`Error returning order ${orderID}`, {
        variant: "error",
        });
        setOpen(false);
      });
    };

    return (
      <>
      <Dropdown title={props.returned ? "Returned" : "Return"} noCaret disabled={props.returned}>
        <Dropdown.Item onClick={() => returnOrder(true)}>Now</Dropdown.Item>
        <Dropdown.Item onClick={() => returnOrder(false)}>Later</Dropdown.Item>
      </Dropdown>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Return Order</DialogTitle>
        <DialogContent>
        <DialogContentText>
          Please provide a reason for returning order {orderID} {returnNow ? "now" : "later"}.
        </DialogContentText>
        <textarea
          style={{ width: '100%', minHeight: '100px', marginTop: '10px' }}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Enter return reason"
        />
        </DialogContent>
        <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleReturn} color="primary">Return</Button>
        </DialogActions>
      </Dialog>
      </>
    );
    // // return <Button onClick={returnOrder}>Return</Button>;
    // return <Dropdown title={props.returned?"Returned":"Return"} noCaret disabled={props.returned}>
    //   <Dropdown.Item onClick={() => returnOrder(true)}>Now</Dropdown.Item>
    //   <Dropdown.Item onClick={() => returnOrder(false)}>Later</Dropdown.Item>
    // </Dropdown>
  };

  
  const ReattemptButton = (props: {reattemptedCount: boolean}) => {

    const [reattemptNow, setReattemptNow] = React.useState(false)
    const [chargeReattempt, setChargeReattempt] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    
    const reattemptOrder = (reattemptNow: boolean, chargeReattempt: boolean) => {
      if (!window.confirm(`Are you sure you want to reattempt order ${orderID} and ${chargeReattempt? "charge": "not charge"} customer? This action cannot be undone.`)) {
        return;
      }
      APIReq.orders
        .reattemptOrder(orderID, reattemptNow, chargeReattempt)
        .then((res) =>{
          setOpen(false)
          enqueueSnackbar(`Order ${orderID} set to returned`, {
            variant: "success",
          })}
        )
        .catch((err) =>{
          setOpen(false)
          enqueueSnackbar(`Error returning order ${orderID}`, {
            variant: "error",
          })}
        );
    };
    // return <Button onClick={returnOrder}>Return</Button>;
    // return <Dropdown title={props.returned?"Returned":"Return"} noCaret disabled={props.returned}>
    //   <Dropdown.Item onClick={() => returnOrder(true)}>Now</Dropdown.Item>
    //   <Dropdown.Item onClick={() => returnOrder(false)}>Later</Dropdown.Item>
    // </Dropdown>
    return <>
     {/* open a dialog on button click with two options for reattempting now or later. Followed by a check box with an option to charge customer. */}
     <Button onClick={() => setOpen(true)} size="small">Reattempt({props.reattemptedCount})</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Reattempt Order</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reattempt order <i>({orderID})</i> <b>{reattemptNow? "now": "later"}</b> and <b>{chargeReattempt? "charge": "not charge"}</b> customer? This action cannot be undone.
          </DialogContentText>
          <Button onClick={() => !reattemptNow && setReattemptNow(!reattemptNow)}  variant="contained" color={reattemptNow ? "primary": "default"}>
            Now
          </Button>
          {"   "}
          <Button onClick={() => reattemptNow && setReattemptNow(!reattemptNow)} variant="contained" color={!reattemptNow ? "primary": "default"}>
            Later
          </Button>
          <br/>
          <FormControlLabel
            control={<Checkbox checked={chargeReattempt} onChange={() => setChargeReattempt(!chargeReattempt)} name="chargeReattempt" />}
            label="Charge Customer"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => reattemptOrder(reattemptNow, chargeReattempt)} color="primary">
            Reattempt
          </Button>
        </DialogActions>
      </Dialog>
    </>
  };


  const ResetButton = () => {
    const resetOrder = () => {
      if (!window.confirm(`Are you sure you want to reset order ${orderID}`)) {
        return;
      }
      APIReq.orders
        .resetOrder(orderID)
        .then((res) =>
          enqueueSnackbar(`Order ${orderID} will fall to previous order status`, {
            variant: "success",
          })
        )
        .catch((err) =>
          enqueueSnackbar(`Error occurred while order ${orderID} reset`, {
            variant: "error",
          })
        );
    };
    return <Button style={{ color: "red" }} onClick={resetOrder}>Reset</Button>;
  };

  const TaskFailed = ({taskType: orderStatus}: {
    taskType: "PICKUPFAILED"|"DROPOFFFAILED"
  }) => {

    const taskFailed = () => {
      dispatch(setFailOrderTask({
        orderID,
        orderStatus
      }))
    };

    return (
      <Button onClick={taskFailed} style={{ color: "red" }}>
        {orderStatus === "PICKUPFAILED" && "PICKUP FAILED"}
        {orderStatus === "DROPOFFFAILED" && "DROP FAILEd"}
      </Button>
    );
  };


  const ButtonChoices = () => {
    if (["NEW", "ASSIGNED"].includes(orderStatus)) {
      return (
        <>
          <CancelButton />
          <RescheduleButton />
        </>
      );
    } else if (["STARTED", "PICKUPREACHED"].includes(orderStatus)) {
      return (
        <>
          <TaskFailed taskType="PICKUPFAILED" />
        </>
      )
    } else if (["WH_DROP"].includes(orderStatus)) {
      return (
        <>
          <ReturnButton returned={orderDetails.returnMarked}/>
          <RescheduleButton />{" "}
        </>
      );
    } else if (["DROPOFFFAILED"].includes(orderStatus)) {
      return (
        <>
          <RescheduleButton />{" "}
          <ResetButton />{" "}
        </>
      );
    } else if (["PICKUPCOMPLETED", "WH_PICK", "DROPREACHED", "RETURN_INITIATED"].includes(orderStatus)) {
      return (
        <>
          {!orderDetails.returnMarked && <ReattemptButton reattemptedCount={orderDetails?.reattemptCount || 0}/>}
          <ReturnButton returned={orderDetails.returnMarked}/>
          <TaskFailed taskType="DROPOFFFAILED" />
        </>
      )
    }
    return <></>;
  };

  const ButtonChoicesCached = useCallback(ButtonChoices, [orderStatus, orderID]);

  return (
    <>
      <hr />
      <div className="d-flex justify-right">
        <ButtonChoicesCached />
      </div>
    </>
  );
};

export default OrderActions;