import APIReq from "services/api"
import { BusinessDetailsInterface, OrdersInterface, updateBusinessDetails } from "states/data/orders"

export const setBusinessData = (ordersData: OrdersInterface[]) => (dispatch, getState) => {
    const current_businesses_code = getState().ordersData.businessesDetail.map(biz => biz.businessCode)
    const new_business_codes = ordersData.reduce((acc, orderData) => {
        if(orderData.business_code && current_businesses_code.indexOf(orderData.business_code) === -1){
            acc.push(orderData.business_code)
        }
        return acc
    }, [] as string[])
    if(new_business_codes.length > 0){
    APIReq.orders.getBusinessNames(new_business_codes)
        .then((res: { data: { biz_names: BusinessDetailsInterface[] } }) => {
            const biz_names = res.data.biz_names;
            const unfounded_bizs = new_business_codes.filter(biz => biz_names.findIndex(biz_name => biz_name.businessCode === biz) === -1)
            unfounded_bizs.forEach(unfoundedBiz => biz_names.push({ 'businessCode': unfoundedBiz, 'businessName': unfoundedBiz }))
            // const biz_names_sorted = biz_names.sort((biz1, biz2)=> biz1.businessName.toLowerCase().localeCompare(biz2.businessName.toLowerCase()))
            dispatch(updateBusinessDetails(biz_names))
        })
    .catch(e => {})}
}