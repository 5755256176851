import { setAuthenticated, unsetAuth } from 'states/auth';


const isAdmin = (admin_user_data) => (dispatch) => {

    if (!admin_user_data?.operations?.manager) {
        dispatch(unsetAuth())
    }
    else {
        dispatch(setAuthenticated({ email: admin_user_data.email, canViewUnverifiedAgents: admin_user_data?.agents?.view_unverified_agents || false, isMultiTenantSystem: admin_user_data?.isMultiTenantSystem || false }))
        // if (admin_user_data?.agents?.view_unverified_agents) {
        //     canViewUnverifiedAgents()
        // }
    }
}

export default isAdmin;