import auth from "./auth";
import agents from "./agents";
import orders from "./orders";
import configs from "./configs";
import general from "./general";
import nucleus from "./nucleus";
import nucleusApi from "./nucleusApi";
import OrderApis from "./ordersApi";
import AgentApis from "./agentApi";

const APIReq = {
  auth,
  agents,
  orders,
  configs,
  general,
  nucleus,
  nucleusApi,
  OrderApis,
  AgentApis
}

export const DoApiRequest = (apiReq, onSuccess?, onError?, args?) => {
  return (args ? apiReq(args): apiReq).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  }).catch((error) => {
    if (onError) {
      onError(error);
    }
  });
}

export default APIReq;