import { useQuery } from "@tanstack/react-query";
import { UNVERIFIED_AGENT_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import APIReq from "services/api";
import { authDataSlice } from "states/auth";
import { FilterOption } from "types/filter-types";
import { getQueryParamJSONObject, useQueryParams } from "utils/history";


// unverified agents data example         [{
// "agentID": "7d839515ea524c558e9436951c8fab07",
//     "agent_info": {
//     "created_date": 1702903838515,
//         "phone": "3131313131",
//             "photoURL": "https://tp-images.sgp1.digitaloceanspaces.com/agent/documents/yzEDIG_1702909328522.png",
//                 "address": "The Forum Mall, 21, Hosur Rd, Chikku Lakshmaiah Layout, Koramangala, Bengaluru, Karnataka 560095, India",
//                     "homeAddress": {
//         "coordinates": {
//             "lat": 12.934438393202614,
//                 "lng": 77.61126682162285
//         },
//         "fullAddress": "The Forum Mall, 21, Hosur Rd, Chikku Lakshmaiah Layout, Koramangala, Bengaluru, Karnataka 560095, India",
//             "pinCode": "560095",
//                 "placeName": "Forum Rex Walk",
//                     "region": 0
//     },
//     "name": "Test Agent",
//         "documentDetails": {
//         "DLNumber": "DL141431WEOTWOITIW",
//             "aadharNumber": "DL14W4WFDSNKGS",
//                 "panNumber": "FKGJN4252K"
//     }
// }
//         },]

export const useFetchUnverifiedAgentFilters = () => {
    const authData = useSelector(authDataSlice);
    const { data, error, isLoading, isFetching, refetch, isFetched } = useQuery<{ [propName: string]: FilterOption }>(
        ['unverifiedAgentFilters'],
        APIReq.AgentApis.getUnverifiedAgentFilters, {
        staleTime: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
        cacheTime: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
        enabled: authData.canViewUnverifiedAgents,
    });

    if (!authData.canViewUnverifiedAgents) {
        return {
            data: null,
            error: true,
            isLoading: false,
            isFetching: false,
            refetch: () => { },
        };
    }

    return {
        data,
        error,
        isLoading,
        isFetching,
        refetch,
        isFetched,
    };
};

export const useFetchUnverifiedAgents = () => {
    const authData = useSelector(authDataSlice);

// fetch unverified agents filters and when it's updated refetch the unverified agents with the new filters
    const unverifriedAgentsFilterQuery = useQueryParams().get(UNVERIFIED_AGENT_FILTER_QUERY_PARAM);
    const unverifriedAgentsFilterQueryList = useMemo(() => {
        return getQueryParamJSONObject(UNVERIFIED_AGENT_FILTER_QUERY_PARAM)
    }, [unverifriedAgentsFilterQuery])
    
    const { 
        data: unverifiedAgentsData,
        error: unverifiedAgentsError,
        isLoading: unverifiedAgentsLoading,
        isFetching: unverifiedAgentsFetching,
        refetch: unverifiedAgentsRefetch,
    } = useQuery(
        ['unverifiedAgents', unverifriedAgentsFilterQueryList],
        ()=>APIReq.AgentApis.getUnverifiedAgents(unverifriedAgentsFilterQueryList),
        {
            enabled: authData.canViewUnverifiedAgents,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchInterval: 5 * 60 * 1000, // every 5 minutes
            staleTime: 5 * 60 * 1000, // 12 hours in milliseconds
            cacheTime: 15 * 60 * 1000, // 12 hours in milliseconds
        }
        )
    
    if (!authData.canViewUnverifiedAgents) {
        return {
            data: null,
            error: true,
            isLoading: false,
            isFetching: false,
            refetch: () => { },
        };
    }

    return {
        data: unverifiedAgentsData,
        error: unverifiedAgentsError,
        isLoading: unverifiedAgentsLoading,
        isFetching: unverifiedAgentsFetching,
        refetch: unverifiedAgentsRefetch,
    };
}

