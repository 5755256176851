import { Avatar, Chip, makeStyles } from "@material-ui/core";


import { theme } from "styles/theme";
import { useHistory } from "react-router";

import {
  getQueryParamJSONArray, toggleQueryParam, updateQueryParam,
  useQueryParams
} from "utils/history";
import { FILTER_BAR_OPEN_QUERY_PARAM } from ".";
import { FilterInterface, FilterOptionWithFilterType } from "types/filter-types";
import { ORDER_FILTER_QUERY_PARAM } from "global-constants/orders/query_params";
import { generateFilterQuery, prepareFilter } from "apps/ops-dashboard/services/filters";
import { AGENT_GENERAL_FILTER_QUERY_PARAM, UNVERIFIED_AGENT_FILTER_QUERY_PARAM } from "global-constants/agents/query_params";
import { useFetchUnverifiedAgentFilters } from "apps/ops-dashboard/custom-hooks/query-hooks/query-unverified-agents";
import { orderFilters } from "apps/ops-dashboard/services/orders/filters";
import { agentsFilters } from "apps/ops-dashboard/services/agents/filters";
import { useMemo } from "react";

const styles = makeStyles({
  container: {
    position: "absolute",
    top: 56,
    width: "280px",
    zIndex: -1,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});

const FilterChips = () => {
  const classes = styles();
  const history = useHistory();
  const query = useQueryParams();
  
  const { data: unverifiedAgentFiltersData,
    error: unverifiedAgentsFiltersError,
  isFetched: unverifiedAgentsFiltersFetched} = useFetchUnverifiedAgentFilters();

  const filterOptions = useMemo(() => {
    return prepareFilter(null, unverifiedAgentFiltersData);
  }, [unverifiedAgentsFiltersFetched]);
  const handleDelete = (key, value, deleteFilterOn: FilterOptionWithFilterType['filterOn']) => {
    let filterQueryParam;
    let filterData
    if (deleteFilterOn === 'order') {
      filterQueryParam = ORDER_FILTER_QUERY_PARAM;
      filterData = orderFilters;
    }
    else if (deleteFilterOn === 'agent') {
      filterQueryParam = AGENT_GENERAL_FILTER_QUERY_PARAM;
      filterData = agentsFilters
    }
    else if (deleteFilterOn === 'unverified_agents' && unverifiedAgentFiltersData && !unverifiedAgentsFiltersError) {
      filterQueryParam = UNVERIFIED_AGENT_FILTER_QUERY_PARAM
      filterData = unverifiedAgentFiltersData
    }
    else {
      return
    }
    history.replace(
      updateQueryParam(filterQueryParam, generateFilterQuery(key, value, deleteFilterOn, filterData))
    );
  };

  const openSideBar = () => {
    history.replace(
      toggleQueryParam(FILTER_BAR_OPEN_QUERY_PARAM)
    )
  }
  

  return (
    <div className={classes.container}>
      {getQueryParamJSONArray(ORDER_FILTER_QUERY_PARAM).flatMap(
        (filter: FilterInterface) => {
          return filter.values.map((value) => {
            const filterOption = filterOptions.find(
              (filterOption) => filterOption.key === filter.key
            );
            if (!filterOption) {
              return <div key={filter.key + value}></div>;
            }
            const filterItem = filterOption.items.find(
              (f) => f.value === value
            );
            if (!filterItem) {
              return <div key={filter.key + value}></div>;
            }
            return (
              <Chip
                key={filter.key + value}
                color="primary"
                label={filterItem.name}
                onDelete={() => handleDelete(filter.key, value, 'order')}
                component="code"
                className={classes.chip}
                onClick={() => openSideBar()}
                size="small"
                avatar={<Avatar>O</Avatar>}
              />
            );
          });
        }
      )}
      {getQueryParamJSONArray(AGENT_GENERAL_FILTER_QUERY_PARAM).flatMap(
        (filter: FilterInterface) => {
          return filter.values.map((value) => {
            const filterOption = filterOptions.find(
              (filterOption) => filterOption.key === filter.key
            );
            if (!filterOption) {
              return <div key={filter.key + value}></div>;
            }
            const filterItem = filterOption.items.find(
              (f) => f.value === value
            );
            if (!filterItem) {
              return <div key={filter.key + value}></div>;
            }
            return (
              <Chip
                key={filter.key + value}
                color="primary"
                label={filterItem.name}
                onDelete={() => handleDelete(filter.key, value, 'agent')}
                component="code"
                className={classes.chip}
                onClick={() => openSideBar()}
                size="small"
                avatar={<Avatar>A</Avatar>}
              />
            );
          });
        }
      )}
      {getQueryParamJSONArray(UNVERIFIED_AGENT_FILTER_QUERY_PARAM).flatMap(
        (filter: FilterInterface) => {
          return filter.values.map((value) => {
            
            const filterOption = filterOptions.find(
              (filterOption) => filterOption.key === filter.key
            );
           
            if (!filterOption) {
              return <div key={filter.key + value}>
              </div>;
            }
            const filterItem = filterOption.items.find(
              (f) => f.value === value
            );
            if (!filterItem) {
              return <div key={filter.key + value}></div>;
            }
            return (
              <Chip
                key={filter.key + value}
                color="primary"
                label={JSON.stringify(filterItem.name)}
                onDelete={() => handleDelete(filter.key, value, 'unverified_agents')}
                component="code"
                className={classes.chip}
                onClick={() => openSideBar()}
                size="small"
                avatar={<Avatar>UA</Avatar>}
              />
            );
          });
        }
      )}
    </div>
  );
};

export default FilterChips;
