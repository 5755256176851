import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UnverifiedAgentsData {
    agentID: string;
    agent_info: {
        created_date: number;
        phone: string;
        photoURL: string;
        address: string;
        homeAddress: {
            coordinates: {
                lat: number;
                lng: number;
            };
            fullAddress: string;
            pinCode: string;
            placeName: string;
            region: number;
        };
        name: string;
        documentDetails: {
            DLNumber: string;
            aadharNumber: string;
            panNumber: string;
        };
    };
}

export interface HoveredUnverifiedAgent {
    object: UnverifiedAgentsData['agent_info'] | null;
    sidebarHover?: boolean;
}

export interface RejectUnverifiedAgents {
    agentID: string;
    name: string;
}

const hoveredAgent: { object: UnverifiedAgentsData['agent_info'] } | null = null;

const rejectUnverifiedAgentsInitialValue: RejectUnverifiedAgents[] = [];

const initialState: {
    hoveredAgent: HoveredUnverifiedAgent | null;
    rejectUnverifiedAgents: RejectUnverifiedAgents[];
} = {
    hoveredAgent,
    rejectUnverifiedAgents: rejectUnverifiedAgentsInitialValue,
};


export const unverifiedAgentsSlice = createSlice({
    name: 'unverifiedAgents',
    initialState,
    reducers: {
        setUnverifiedAgentHovered: (state, action: PayloadAction<HoveredUnverifiedAgent>) => {
            state.hoveredAgent = action.payload;
        },
        setRejectUnverifiedAgents: (state, action: PayloadAction<RejectUnverifiedAgents[]>) => {
            state.rejectUnverifiedAgents = action.payload;
        },
        resetRejectUnverifiedAgents: (state) => {
            state.rejectUnverifiedAgents = rejectUnverifiedAgentsInitialValue;
        }
    },
});

export const {
    setUnverifiedAgentHovered,
    setRejectUnverifiedAgents,
    resetRejectUnverifiedAgents,
} = unverifiedAgentsSlice.actions;
export const hoveredUnverifiedAgentSlice = (state: any) => state.unverifiedAgentsData.hoveredAgent;
export const rejectUnverifiedAgentsSlice = (state: any) => state.unverifiedAgentsData.rejectUnverifiedAgents;
export default unverifiedAgentsSlice.reducer;