import { Badge, Button, makeStyles } from "@material-ui/core";
import { updateAgentsWithFilter } from "apps/ops-dashboard/services/agents/filters";
import { REGION_QUERY_PARAM } from "apps/ops-dashboard/services/regions";
import { useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serviceableConfigSlice } from "states/data/configs";
import { updateLatLng } from "states/deck.gl/maps";

import { theme } from "styles/theme";
import { getQueryParam, updateQueryParam, useQueryParams } from "utils/history";

const styles = makeStyles({
    regionSelectForm: {
        position: "fixed",
        left: theme.spacing(55),
        top: theme.spacing(3),
        // background: "rgba(255,255,255, 0.95)",
        zIndex: 10,
        // boxShadow: "1px 3px 3px #aaa",
        borderRadius: 4
    },
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 120,
        // padding: theme.spacing(1)    
    },
})
function RegionSelect() {
    const classes = styles();
    const history = useHistory();
    const query = useQueryParams();
    const [regionState, setRegionState] = useState(0)
    const dispatch = useDispatch();
    const origOrdersData = useSelector(
        (state: any) => state.ordersData.origOrdersData
    );
    const regions = useSelector(serviceableConfigSlice);
    const regionsOrderCount = useMemo(() => {
        const regionsOrderCountObj: {[key: string]: number} = {}
        regions.forEach(region => {
            regionsOrderCountObj[region.code.toString()] = 0
        })
        origOrdersData.forEach(order => {
            Object.keys(regionsOrderCountObj).forEach(key => {
                const orderLatLng = { lat: order.pickLocation.lat, lng: order.pickLocation.lng }
                // if (presentInRegion(parseInt(key), orderLatLng)) {
                //   regionsOrderCountObj[key]++;
                // }
                if (order.region.toString() === key) {
                    regionsOrderCountObj[key]++;
                }
            })
        });
        return regionsOrderCountObj
    }, [origOrdersData])

    useLayoutEffect(() => {
        const queryRegion = getQueryParam(REGION_QUERY_PARAM)
        if (!queryRegion) {
            history.replace(updateQueryParam(REGION_QUERY_PARAM, "0"))
        }
        if (regionState.toString() !== queryRegion) {
            setRegionState(parseInt(queryRegion as string))
            const selectedRegion = regions
                .find(region => region.code.toString() === queryRegion)
            if (!selectedRegion) {
                history.replace(updateQueryParam(REGION_QUERY_PARAM, "0"))
                return
            }
            const selectedRegionLatLng = selectedRegion.centerCoordinates
            dispatch(updateLatLng(selectedRegionLatLng));
            dispatch(updateAgentsWithFilter());
        }
    }, [query]);

    const onRegionSelect = (code: string) => {
        history.replace(updateQueryParam(REGION_QUERY_PARAM, code))
    }

    return (
        <div className={`${classes.regionSelectForm} text-center d-flex justify-space-between`}>
            {/* <FormControl className={classes.formControl} >
                <Select
                    // labelId="region-selector-label"
                    id="region-selector"
                    value={regionState}
                    onChange={(e) => onRegionSelect(e.target.value as string)}
                > */}
            {regions
                    .filter(region => region.code !== regionState)
                    .map(region => 
                        <div key={region.code} style={{marginRight: "1rem"}}>
                        <Badge badgeContent={regionsOrderCount[region.code]} color="primary">
                            <Button variant="contained" onClick={()=>onRegionSelect(region.code)}>{region.name}</Button>
                        </Badge>
                    </div>
                    )}
                {/* </Select> */}
            {/* </FormControl> */}
        </div>
    )
}

export default RegionSelect;
