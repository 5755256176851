import { UnverifiedAgentFilterResponse } from "types/agent-types";
import { FilterOption } from "types/filter-types";
import { ApiClientDelete, ApiClientGet, ApiClientPost } from "utils/api";


const AgentApis = {
    getAgent: (agentID: string) => ApiClientGet(`/m/admin/agent/${agentID}`),
    getUnverifiedAgentFilters: (): Promise<{ [propName: string]: FilterOption }> => transformation.unverifiedAgentFilters(ApiClientGet(`/m/admin/ops/agents/onboarding/unverified`)),
    getUnverifiedAgents: (filters: { [propName: string]: string }) => transformation.unverifiedAgents(ApiClientPost(`/m/admin/ops/agents/onboarding/unverified`, filters)),
    deleteUnverifiedAgent: (agentID: string) => ApiClientDelete(`/m/admin/agent/${agentID}/delete`),
    setUnverifiedAgentDnD: (agentID: string, dnd: boolean, duration?: number, reason?: string) => ApiClientPost(`/m/admin/agent/${agentID}/dnd`, { 
        dnd: dnd,
        duration: duration || undefined,
        reason: reason || undefined,
     }),
}

const transformation = {
    unverifiedAgentFilters: (api_promise) => {
        return api_promise  
            .then((response) => response.data)
            .then((filters: UnverifiedAgentFilterResponse) => Object.entries(filters.filters).reduce((acc, [key, value]) => {
                acc[key] = {
                    name: key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase()),
                    key: key,
                    items: value.map((item) => ({
                        name: item.display_name,
                        value: item.key,
                    })),
                    filterFunction: (dataValue, optionValue) => dataValue === optionValue,
                    elementType: "radio",
                    default: filters.filter_defaults[key] || null,
                    filterOn: "unverified_agents",
                }
                return acc;
            }, {}));
    },  
    unverifiedAgents: (api_promise) => {
        return api_promise.then((response) => response.data.results);
    }
}

export default AgentApis
