import { resetLayers } from "states/deck.gl/layers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryParams } from "utils/history";
import AgentsLayer from "./agents";
import OrdersLayer from "./orders";
import AgentTaskLogLayer from "./task-log";
import { SIDEBAR_TYPE_REORDER_AGENT_QUEUE, SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE, SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY } from "global-constants/agents/query_params";
import { SIDEBAR_QUERY_PARAM } from "global-constants/general";
import UnverifiedAgentLayers from "./unverified-agents";

const DeckLayers = (props) => {
  const query = useQueryParams();
  const dispatch = useDispatch();

  const [sidebarQueryParam, setSidebarQueryParam] = useState<string | null>(
    null
  );
  const [renderIndex, setRenderIndex] = useState(0);

  // Avoid other query changes to trigger reload of states
  useEffect(() => {
    if (sidebarQueryParam === query.get(SIDEBAR_QUERY_PARAM)) {
      return;
    }
    setSidebarQueryParam(query.get(SIDEBAR_QUERY_PARAM));
    setRenderIndex(renderIndex + 1)
  }, [query]);

  // Based on different tasks that the user is performing on the sidebar
  // render these layers
  const onSidebarTaskChange = (sidebarTask) => {
    switch (sidebarQueryParam) {
      case SIDEBAR_TYPE_REORDER_AGENT_QUEUE:
        dispatch(resetLayers());
        return <AgentsLayer />;
      case SIDEBAR_TYPE_REORDER_ASSIGN_AGENT_QUEUE:
        dispatch(resetLayers());
        return <AgentsLayer />;
      case SIDEBAR_TYPE_AGENT_TASK_LOG_HISTORY:
        dispatch(resetLayers());
        return <AgentTaskLogLayer />;

      // Added render index to this because, there may be multiple
      // route changes that will lead to default. Which may cause
      // OrdersLayer to not rerender(react vdom i guess) after resetLayers
      // leading to missing order icons on screen
      default:
        dispatch(resetLayers());
        return (
          <>
            <OrdersLayer key={renderIndex} />
            <AgentsLayer key={renderIndex + 1} />
            <UnverifiedAgentLayers key={renderIndex + 2} />
          </>
        );
    }
  }

  return useMemo(() => onSidebarTaskChange(sidebarQueryParam), [sidebarQueryParam])
};

export default DeckLayers;
