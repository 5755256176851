import { Chip, makeStyles } from "@material-ui/core";
import { timeFromNow } from "utils/datetime";

const styles = makeStyles({
  photoContainer: {
    width: 48,
    height: 48,
  },
  photo: {
    borderRadius: 100,
    objectFit: "contain",
    width: "100%",
    borderWidth: 2,
    borderStyle: "solid",
  },
});

function AgentInfo({ agentData, showLastUpdated = true }) {
  const agentInfo = agentData.agent_info;

  const classes = styles();
  return (
    <div className={`d-flex align-center`}>
      <div className={`${classes.photoContainer} mr-2`}>
        <img
          className={classes.photo}
          src={agentInfo.photoURL}
          alt={agentInfo.name}
          style={{
            borderColor: agentData.onDutyMode ? "green" : "red",
          }}
        />
      </div>
      <div>
        {agentInfo.name} {"  "}
        <Chip label={agentInfo.model} size="small" color="primary"/>
        <br />
        {agentInfo.phone}
        {showLastUpdated ? (
          <>
            <br />
            Updated{" "}
            {timeFromNow(agentData.agent_info?.lastKnownLocation?.timestamp)}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AgentInfo;
