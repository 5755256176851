import { Button, Chip } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Badge, Panel } from "rsuite";
import { WhOrderQueueItem } from "states/data/agents";
import { warehousesConfigSlice } from "states/data/configs";

function WHOrders({
  whOrders,
  limit = 0,
  whDrop = false,
  whPick = false,
  removeOrderCallback = undefined,
}: {
  whOrders: Array<WhOrderQueueItem>;
  limit?: number;
  whDrop?: boolean;
  whPick?: boolean;
  removeOrderCallback?: (event) => any;
}) {
  let orders = whOrders ? [...whOrders].map(element => element.orders) : [];
  const whConfig = useSelector(warehousesConfigSlice);

  const getWhName = (whID: string) => {
    const wh = whConfig.find(x => x.warehouseID === whID);
    return wh ? wh.name : whID;
  };

  const [whOrdersLimit, setWhOrdersLimit] = React.useState(limit);

  const resetLimit = () => {
    setWhOrdersLimit(limit);
  }

  const makeLimitUnlimited = (wh_orders_length) => {
    setWhOrdersLimit(wh_orders_length);
  }

  const handleExpand = (wh_orders_length) => {
    if (whOrdersLimit === limit) {
      makeLimitUnlimited(wh_orders_length);
    } else {
      resetLimit();
    }
  }

  const handleExpandBtnText = useMemo(() => {
    if (whOrdersLimit === limit) {
      return "Show more";
    } else {
      return "Show less";
    }
  }, [whOrdersLimit]);


  return (
    <div>
      <div className="d-flex align-center">
        <div className={`font-md pr-1`}>
          {whDrop ? "Warehouse Drops" : ""}
          {whPick ? "Warehouse Picks" : ""}
        </div>
        <Badge
          style={{ background: "gray" }}
          content={`${whOrders?.length} warehouses`}
        />
      </div>
      {orders.length ? (
        whOrders.map(whOrder => (
          // restrict hight using rsuite css
        <Panel bordered bodyFill  key={whOrder.warehouseID}>
        <div className={`m-1 d-flex flex-column`}>
          <div className={`font-sm pr-1`}>
            {getWhName(whOrder.warehouseID)}{"  "} <Badge
            style={{ background: "gray" }}
            content={`${whOrder.orders?.length} orders`}
          />
          </div>
              {whOrder.orders?.length ? (
                // add styling fix the max width of 3 chips and make it scrollable
                <div className={`d-flex flex-column`}>
            <div className={`mt-1 d-flex flex-wrap`} style={{maxHeight: "5rem", overflow: "scroll"}}>
                  {(whOrder.orders?.length ? (whOrdersLimit ? whOrder.orders.slice(0, whOrdersLimit): whOrder.orders): []).map((x, i) => (
                <Chip
                  key={i}
                  label={x.orderID.slice(-7)}
                  variant="outlined"
                  onDelete={removeOrderCallback ? () => removeOrderCallback(x) : undefined}
                />
                  ))}
                </div>
                 {whOrdersLimit && (whOrder.orders?.length > limit) ? <Button onClick={() => handleExpand(whOrder.orders?.length)}>{handleExpandBtnText}</Button> : ""}
              </div>
          ) : (
            ""
          )}
          </div>
  </Panel>))
          ):( <div className={`font-sm`}>No orders</div>)}
    </div>
  );
}

export default WHOrders;
